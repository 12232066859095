.notification-three-dot-modal {
  width: fit-content;
  .MuiModal-backdrop {
    background-color: transparent;
  }
  .three-dot-paper {
    display: flex;
    flex-direction: column;
  }
  .cancel-schedule-btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
