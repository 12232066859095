.credit-check-popup {
    width: 15rem;
    padding: 0px !important;

    .title {
        color: #000650;
        font-family: Moderat;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
    }

    .credit-check-data {
        color: #101010;
        font-family: Moderat;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
    }
}
  
  