.invoice-dialog {
  .MuiListItem-root {
    align-items: center;
  }
  .totalAmount-wrapper {
    display: block;
    text-align: center;
    .totalAmount {
      font-family: Moderat-Medium;
    }
  }
  .MuiDialogContent-root {
    padding-top: 0;
  }
  .list-item-content {
    width: 100%;
    .bold-text {
      font-family: "Moderat-Medium";
      font-weight: 500;
      font-size: 18px;
    }
  }
  .action-btn-wrapper {
    margin-left: 1rem;
  }
  .list-container {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    margin-top: 1.5rem;
  }
}
