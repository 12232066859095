.eligible-div {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.eligible-icon {
  color: white;
  stroke-width: 3;
  background-color: #00804a;
  border-radius: 50%;
}
.not-eligible-icon {
  color: white;
  stroke-width: 3;
}
.bredcrum-icon {
  width: 100%;
  align-items: center;
  height: 100%;
}
