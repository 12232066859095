.custom-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.payslip {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  flex: 1;
  gap: 16px;
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
  }
  .attatchment-rotate {
    transform: rotate(90deg);
    margin-right: 4px;
  }
  .message-style {
    text-align: center;
  }
}
.button-style {
  display: flex;
  align-items: center;
  border: 1px solid #e6e6ee;
  padding: 8px;
  border-radius: 8px;
}
