@import "../../variables";

.checkbox-dialog {
  padding: 0px !important;
  margin-top: 0.5rem;
  width: 18rem;
  .dialog-action {
    display: flex;
    flex-direction: column;
    button {
      width: 100%;
    }
  }
  .MuiModal-backdrop {
    background-color: transparent;
  }
}

.date-select-content-wrapper {
  display: flex;
  align-items: center;
  .select {
    color: $primary;
  }
  input {
    text-align: right;
    color: $primary;
  }
  .select-formcontrol {
    flex: 1.3;
  }
  .datepicker-formcontrol {
    flex: 2;
  }
  svg {
    color: $primary;
  }
}
