.edit-new-notification {
  padding-top: 2rem;
  padding-left: 1.3rem;
  padding-right: 1.3rem;
  .link-wrapper {
    display: flex;
    align-items: center;
  }
  .notification-title-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    .title-btn-wrapper {
      display: flex;
      justify-content: flex-end;
      align-self: center;
    }
    .title-icon {
      cursor: pointer;
      color: var(--primary);
    }
    .title-icon-wrapper {
      margin-left: 0.5rem;
    }
    .title-form-wrapper {
      display: flex;
      align-items: center;
      .title-input {
        input {
          padding: 8px 6px;
        }
      }
    }
    .title-text-wrapper {
      .title-text-with-icon-wrapper {
        display: flex;
        align-items: center;
        .title-text {
          font-size: 1.4rem;
          color: var(--lightNavy);
        }
      }
      .draft-text {
        color: var(--grey60);
      }
    }
  }
  .notification-content-section {
    margin-top: 0.5rem;
    margin-bottom: 12rem;
    .notification-subtitle {
      display: block;
      margin-top: 0.5rem;
      font-size: 0.9rem;
    }
    .recipients-radio-wrapper {
      width: 100%;
      margin-top: 1.5rem;
    }
    .recipients-radio-select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 2rem;
    }
  }
}
