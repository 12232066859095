#auth0-main {
  height: 100vh;
  font-family: Moderat;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}
.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 25px;
}
@media (min-width: 481px) {
  .auth0-lock.auth0-lock .auth0-lock-header-bg {
    background: none;
  }
}
.auth0-lock-header-bg-blur {
  background: none !important;
}
.welcome-text {
  color: #707070;
  font-family: Moderat;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}
.label-text {
  color: #000650;
  font-family: Moderat;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.login-button {
  padding: 16px 20px;
  gap: 10px;
  background: #000650;
  font-family: Moderat;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
}

.disabled-button {
  padding: 16px 20px;
  gap: 10px;
  background: #cccddc;
  font-family: Moderat;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #8082a7;
  border-radius: 8px;
}
.login-input {
  border: 1px solid #e6e6ee;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: white;
}
.field-gap {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.child-div {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.subi-text {
  font-family: Moderat;
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: #000650;
}
.img-text {
  width: 22px;
  height: 26px;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus {
  outline: none;
  border-color: #e6e6ee;
  --tw-ring-color: "transparent";
}
