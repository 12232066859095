.director-details-dialog {
    padding: 0px !important;
    margin-top: 1rem;
    width: 15rem;
    .MuiModal-backdrop {
      background-color: transparent;
    }
    .MuiCardContent-root {
      padding: 16px;
      padding-top: 8px;
      border-radius: 8px;
    }
    .director-info {
      .info-data {
        color: #101010;
        font-family: Moderat;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0px;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .title {
        color: #000650;
        font-family: Moderat;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
      }
      .title-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  