.employees-payments {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
  }
  .message-style {
    text-align: center;
  }
}
.mark-button {
  background-color: #00804a;
  color: white;
  padding: 8px;
  margin-right: 8px;
  transform: rotate(-0deg);
  width: 106px;
  border-radius: 8px;
}
.view-details {
  color: #000650;
  font-family: Moderat-Medium;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  width: Hug (89px);
  height: Hug (18px);
  padding: 0px 12px 0px 0px;
  gap: 8px;
  overflow: hidden;
  text-decoration: underline;
  cursor: pointer;
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
