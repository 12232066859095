@font-face {
  font-family: "Moderat-Light";
  src: url(./assets/fonts/Moderat-Light.ttf) format("ttf");
}
@font-face {
  font-family: "Moderat";
  src: url(./assets/fonts/Moderat-Regular.ttf) format("ttf");
}
@font-face {
  font-family: "Moderat-Medium";
  src: url(./assets/fonts/Moderat-Medium.ttf) format("ttf");
}

h1 {
  font-family: "Moderat-Medium";
  font-weight: 500;
  font-size: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
