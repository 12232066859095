.upload-dialog {
  .custom-dialog-action {
    padding: 0px 24px 24px 24px;
  }
  .abn {
    margin-bottom: 1.3rem;
    color: #707070;
  }
  .MuiDialogContent-root {
    padding: 6px 24px;
  }
  .dropzone-container {
    .dropzone {
      cursor: pointer;
      border: 1px dashed var(--secondary);
      border-radius: 12px;
      padding: 1.5rem;
    }
    padding: 4px 0px 0px 0px;
  }
  .upload-text {
    text-align: center;
    font-family: Moderat-Medium;
  }
  .sec-colored-text {
    color: var(--secondary);
  }
  .filename-wrapper {
    margin-top: 1.3rem;
    .filename {
      color: var(--primary);
      text-decoration: underline;
      font-weight: bold;
    }
    .filesize {
      color: var(--grey);
    }
  }
  .list-item {
    display: flex;
    justify-content: space-between;
    .item-close-icon-wrapper {
      width: 90%;
    }
    .cancel-icon {
      cursor: pointer;
    }
  }
  .list-inner-item-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .green-line {
    margin-top: 0.2rem;
    background-color: var(--green);
    height: 0.5rem;
    width: 100%;
    border-radius: 10px;
  }
}
