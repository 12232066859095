.select-autocomplete-with-label-wrapper {
  width: 100%;
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiAutocomplete-inputRoot {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }
  .input-label {
    margin-bottom: 0.5rem;
  }
}
