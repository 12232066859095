.root-div {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .message-style {
    text-align: center;
  }
  .invoice-text {
    padding: 2px;
  }
  .view-details {
    color: #000650;
    font-family: Moderat-Medium;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: center;
    width: Hug (89px);
    height: Hug (18px);
    padding: 0px 12px 0px 0px;
    gap: 8px;
    overflow: hidden;
    text-decoration: underline;
    cursor: pointer;
  }
  .outstanding-color {
    color: red;
  }
  .paid-color {
    color: #00804a;
  }
}
.custom-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
