.send-modal {
  width: fit-content;
  .MuiModal-backdrop {
    background-color: transparent;
  }
  .schedule-send-btn {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
