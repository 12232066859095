.dialog-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close-icon {
    margin-right: 1rem;
    color: var(--primary);
    &:hover {
      cursor: pointer;
    }
  }
  .icon-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

.dialog-title-wrapper.title-middle {
  margin-top: 0.5rem;
  h2 {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
    padding-left: 3rem;
  }
  .close-icon {
    margin-top: auto;
  }
  .icon-content-wrapper {
    justify-content: center;
    align-items: center;
  }
}

.dialog-title-wrapper.has-close-icon {
  .icon-content-wrapper {
    margin-left: 1rem;
  }
  .icon-content-wrapper {
    width: 93%;
  }
}

.dialog-title-wrapper.has-title-icon {
  .close-icon {
    margin-top: 0;
    align-self: flex-start;
  }
}
