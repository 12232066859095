.text-input-with-label-wrapper {
  margin-top: 1.5rem;
  .text-field {
    width: 100%;
    input {
      padding: 0.7rem;
    }
  }
  .input-label {
    margin-bottom: 0.5rem;
  }
  .textarea {
    .MuiInputBase-root {
      padding: 0;
    }
    textarea {
      padding: 0.7rem;
    }
  }
}
