.root-dashboard {
  font-family: "Moderat";
  gap: 16px;
  padding: 20px;
  > div {
    padding: 24px 0;
  }
  span {
    color: gray;
  }
  .task-logo span {
    color: darkGrey;
  }
  h1 {
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Moderat-Medium;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
  }
  .dashboard-container {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
  .dashboard-box {
    flex: 1;
    height: fit-content;
    padding: 16px;
    border: 1px solid #e6e6ee;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: bold;
      line-height: 23px;
      letter-spacing: 0px;
      text-align: left;
      color: #101010;
    }
    span {
      font-family: "Moderat-Light";
      font-weight: 300;
    }
  }
  .dashboard-skeleton {
    flex: 1;
    height: fit-content;
    border: 1px solid #e6e6ee;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .new-row {
    display: flex;
    gap: 16px;
  }

  .new-row-column {
    flex: 1;
    height: fit-content;
    padding: 8px, 16px, 24px, 16px;
    border-radius: 12px;
    border: 1px solid #e6e6ee;
    color: #e6e6ee;
    .total-collections {
      font-family: "Moderat-Light";
      font-weight: 300;
    }
  }

  .title {
    color: #000650;
    font-family: Moderat-Medium;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    padding: 16px;
    border-radius: 12px;
  }

  .task {
    font-family: "Moderat-Medium";
    font-weight: 500;
    color: #000650;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    padding: 16px;
  }

  .task-row {
    display: flex;
    align-items: center;
    height: 69px;
    padding: 16px;
    gap: 24px;
    color: #e6e6ee;

    .task-logo {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      border: 1px solid #e6e6ee;
      display: flex;
      align-items: center;
      justify-content: center;
      .list-item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: darkGrey;
      }
    }

    .task-content {
      font-family: "Moderat-Medium";
      font-weight: 500;
      color: #101010;
      justify-content: center;
    }

    .review-button {
      font-family: "Moderat-Medium";
      font-weight: 500;
      padding: 8px;
      border-radius: 8px;
      border: 1px solid #000650;
      color: #000650;
      margin-left: auto;
    }
  }

  .display-amount {
    font-family: "Moderat-Medium";
    font-weight: 500;
    color: #101010;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
  }

  .payment-column {
    display: flex;
    flex-direction: column;
    color: #000650;

    .dot-and-status {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
    }

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      margin-right: 4px;
    }

    .paid {
      background-color: #00804a;
    }

    .overdue {
      background-color: #f33f3f;
    }

    .upcoming {
      background-color: #ffad2b;
    }

    .status-text {
      font-family: "Moderat-Light";
      font-weight: 300;
      font-size: 14px;
    }

    .amount {
      font-family: "Moderat-Medium";
      font-weight: 500;
      font-weight: bold;
      margin-left: 15px;
    }
  }
  .progress-line {
    padding: 16px 16px 0px 16px;
  }
  .amount-with-status {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
}
