.claims {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .button-action-style {
    align-items: center;
    justify-content: left;
    display: flex;
    // padding: 16px;
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
  }
  .table-style {
    min-width: 650px;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .message-style {
    text-align: center;
  }
  .button-style {
    display: flex;
    align-items: center;
    border: 1px solid #e6e6ee;
    padding: 8px;
    border-radius: 8px;
  }
  .request-button {
    font-family: "Moderat";
    font-weight: 500;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #000650;
    color: #000650;
  }
  .director-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .three-dot {
    cursor: pointer;
  }
  .attachment-file-wrapper {
    position: relative;
    cursor: default;
    .cancel-icon {
      cursor: pointer;
      position: absolute;
      right: 3px;
    }
  }
}
