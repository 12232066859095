.main-credit {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
  }
  .button-action-style {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 16px;
  }
  .message-style {
    text-align: center;
  }
  .attachment-file-wrapper {
    position: relative;
    cursor: default;
    .cancel-icon {
      cursor: pointer;
      position: absolute;
      right: 3px;
    }
  }
}

.title {
  color: #000650;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

[type="text"]:focus {
  border-color: "transparent";
  --tw-ring-color: "transparent";
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
