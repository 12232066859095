.searchbar-wrapper {
  // .css-eex4z0-MuiInputBase-root {
  //   width: 100%;
  //   border-radius: 8px;
  //   border: 1px solid var(--subi-navy-10, #e6e6ee);
  // }
  // .css-fgwvf1-MuiInputBase-root {
  //   width: 100%;
  //   border-radius: 8px;
  //   border: 1px solid var(--subi-navy-10, #e6e6ee);
  //   padding: 8px;
  // }

  [type="text"]:focus {
    border-color: "transparent";
    --tw-ring-color: "transparent";
  }
  // .css-nwrj3t {
  //   width: 100%;
  //   border-radius: 8px;
  //   border: 1px solid var(--subi-navy-10, #e6e6ee);
  //   padding: 8px;
  // }
  .search-box-css {
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--subi-navy-10, #e6e6ee);
    padding: 8px;
  }
}
