.schedule-notification-dialog {
  .MuiDialogTitle-root {
    padding-bottom: 0.3rem;
    padding-left: 0.3rem;
  }
  .subTitle {
    padding-left: 1.5rem;
  }
  .divider-line {
    margin-top: 1rem;
    margin-right: 1.5rem;
  }

  .schedule-date-select-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .select {
      color: var(--primary);
    }
    input {
      text-align: right;
      color: var(--primary);
      font-weight: bold;
      height: 2rem;
      font-size: 1rem;
    }
    svg {
      color: var(--primary);
    }
  }
  .custom-dialog-action {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .timepicker-formcontrol {
    .Mui-disabled {
      -webkit-text-fill-color: var(--primary) !important;
    }
  }
}

.MuiMultiSectionDigitalClock-root {
  .Mui-disabled {
    display: none;
  }
}
