.company-bredcumb {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  .icon-wrapper {
    display: flex;
    width: 14px;
    align-items: center;
    gap: 10px;
  }
  a{
    display: flex;
    align-items: center;
  }
  .arrow-icon {
    width: 100%;
    align-items: center !important;
    height: 100%;
  }
}
.span-text {
  font-weight: 500;
  color: #000650;
}

h4 {
  color: #000650;
  font-size: 24px;
  letter-spacing: normal;
  font-weight: 500;
}

h6 {
  color: #000650;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: normal;
}
h5 {
  color: #101010;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: normal;
}

.box {
  flex: 1;
  height: fit-content;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e6e6ee;
  color: #101010;
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-span {
  color: #707070;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.box-container {
  display: flex;
  padding: 24px;
  gap: 24px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.company-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 24px !important;
  gap: 24px;
  border: 1px solid #ccc;
}

.child-row {
  display: flex;
  gap: 20px;
}

.box-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.box-content h3 {
  color: #000650;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.box-content h5 {
  color: #101010;
  line-height: 21px;
  font-weight: 300;
  font-size: 14px;
}
.company-info h2 {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #707070;
}

.parent-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
