.companies {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 550px;
  }
  .message-style {
    text-align: center;
  }
  .table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .custom-table {
      table-layout: inherit;
    }
  }
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

// .custom-table {
//   width: 100%;
//   border-collapse: collapse;
//   table-layout: inherit;
// }

.view-credit-check {
  color: #000650;
  font-family: Moderat-Medium;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  width: Hug (89px);
  height: Hug (18px);
  padding: 0px 12px 0px 0px;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  padding-right: 24px;
}
