.mark-style {
  padding: 16px 24px;
  justify-content: center;
}

.card-style {
  width: 181px;
  box-shadow: 0px 3.700000047683716px 32.400001525878906px 0px #00065040;
}
.mark-paid-dialog{
  padding: 0px !important;
  margin-top: 1rem;
  width: 15rem;
  .MuiModal-backdrop {
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .MuiCardContent-root {
    padding: 16px;
    padding-top: 8px;
    border-radius: 8px;
  }
}
.card-style:hover {
 background-color: rgb(233, 231, 249);
}