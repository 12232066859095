.delete-dialog {
  .MuiDialog-paper {
    padding: 24px;
    border-radius: 12px;
  }
  .subTitle {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--noir);
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .warning-icon {
    font-size: 4rem;
    border-radius: 1rem;
  }
  .delete-btn {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    color: white;
  }
}
