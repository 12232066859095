.review-notification-dialog {
  .MuiDialogContent-root {
    .MuiTypography-root {
      text-align: center;
      line-height: 2rem;
    }
    .highlighted-text {
      font-weight: bold;
    }
  }
}
