.boxed-radio-option-wrapper {
  border: 1px solid var(--darkGrey);
  border-radius: 0.5rem;
  flex: 1;
  .MuiRadio-root {
    padding: 0;
    margin-top: 0.1rem;
    margin-right: 0.5rem;
  }
  .subtitle {
    color: var(--noir);
    font-size: 14px;
  }
  .MuiFormControlLabel-root {
    padding: 1.2rem;
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
  }
  .MuiFormControlLabel-label {
    font-size: 18px;
    color: var(--primary);
    font-family: Moderat-Medium;
  }
}
