.sidebar-wrapper {
  .list-item-icon {
    min-width: 0;
    padding-right: 0.8rem;
  }
  .logo-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    .logo-img {
      margin-bottom: 0.1rem;
      width: 5rem;
    }
    .logo-text {
      font-size: 2rem;
    }
  }
  .link {
    width: 100%;
  }
  .tire-img-wrapper {
    display: inline-flex;
    justify-content: center;
    background: #000650;
    padding: 0.4rem;
    border-radius: 5px;
    margin-right: 0.8rem;
  }
  .b2c {
    .tire-img-wrapper {
      background: #5e17eb;
    }
  }
  .active-item {
    background-color: #e6e6ee !important;
    border-radius: 8px;
  }
}
