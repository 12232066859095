.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  .custom-button {
    font-family: Moderat-Medium;
    font-weight: 500;
    display: flex;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    gap: 16px;
    border: 1px solid #e6e6ee;
    font-size: 14px;
    color: #000650;
  }
}
