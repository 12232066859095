.employee-details {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .company-bredcumb {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    .icon-wrapper {
      display: flex;
      width: 14px;
      align-items: center;
      gap: 10px;
    }
    a {
      display: flex;
      align-items: center;
    }
    .arrow-icon {
      width: 100%;
      align-items: center !important;
      height: 100%;
    }
  }
  .span-text {
    font-weight: 500;
    color: #000650;
  }
  .title-section {
    .title {
      color: #000650;
      font-family: Moderat-Medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      border-radius: 12px;
    }
    span {
      font-family: Moderat-Light;
      color: #707070;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
  }
  .box {
    flex: 1;
    height: fit-content;
    padding: 16px;
    border-radius: 12px;
    border: 1px solid #e6e6ee;
    gap: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h6 {
      font-family: Moderat-Medium;
      color: #000650;
      font-size: 16px;
      line-height: 22px;
    }
    h5 {
      font-family: Moderat-Medium;
      color: #101010;
      font-weight: 500;
      font-size: 32px;
      line-height: 40px;
    }
  }
  .textfield-info {
    h1 {
      margin: 0px 0px 24px 0px;
      font-family: Moderat-Medium;
      font-weight: 500;
      font-size: 24px;
      color: #000650;
      line-height: 29px;
    }
    .user-info {
      h2 {
        color: #000650;
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 24px;
      }
    }
  }
  .box-span {
    color: #707070;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
  }

  .box-container {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .button-action-style {
    .reject-button {
      margin-right: 8px;
      transform: rotate(-0deg);
      color: #000650;
      border: 1px solid #000650;
      border-radius: 8px;
      padding: 8px;
    }
    .approve-button {
      background-color: #000650;
      color: white;
      padding: 8px;
      margin-right: 8px;
      transform: rotate(-0deg);
      border-radius: 8px;
    }
  }
}
