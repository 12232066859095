.user-details {
  gap: 16px;
  padding: 20px;
  > div {
    padding: 12px 0;
  }
  .user-info {
    .info-text {
      margin-bottom: 24px;
    }
  }
  .attatchment-rotate {
    transform: rotate(90deg);
    margin-right: 4px;
  }
  .top-section {
    display: flex;
    margin-bottom: 32px;
    margin-top: 12px;
    .MuiGrid-item {
      padding-top: 0px;
    }
  }
  .button-action-style {
    display: flex;
    // padding: 16px;
    gap: 8px;
    margin-left: auto;
    .save-button-disabled {
      border-radius: 8px;
      padding: 8px 12px;
      background-color: #cccddc;
      color: #666a96;
    }
    .save-button-enabled {
      border-radius: 8px;
      padding: 8px 12px;
      background-color: #000650;
      color: white;
    }
    .cancel-button {
      color: #000650;
    }
  }
  .company-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    padding: 24px !important;
    gap: 24px;
    border: 1px solid #ccc;
  }

  .child-row {
    display: flex;
    gap: 20px;
  }

  .box-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  .box-content h3 {
    color: #000650;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  .box-content h5 {
    color: #101010;
    line-height: 21px;
    font-weight: 300;
    font-size: 14px;
  }
  .company-info h2 {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #707070;
  }

  .parent-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  .reject-button {
    margin-right: 8px;
    transform: rotate(-0deg);
    color: #000650;
    border: 1px solid #000650;
    border-radius: 8px;
    padding: 8px;
  }
  .approve-button {
    background-color: #000650;
    color: white;
    padding: 8px;
    margin-right: 8px;
    transform: rotate(-0deg);
    border-radius: 8px;
  }
}
