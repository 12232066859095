@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: #000650;
  --secondary: #5e17eb;
  --grey: #9f9f9f;
  --darkGrey: #cccddc;
  --grey60: #707070;
  --green: #00804a;
  --noir: #101010;
  --lightNavy: #8082a7;
}
